import {
  ChatBubbleLeftEllipsisIcon,
  Cog8ToothIcon,
  EnvelopeIcon,
  HomeIcon,
  PencilSquareIcon,
  ViewColumnsIcon
} from '@heroicons/react/24/outline'

export default [
  {
    id: 'dashboard',
    title: 'Dashboard',
    icon: <HomeIcon />,
    navLink: '/'
  },
  {
    id: 'chat',
    title: 'Chat',
    icon: <ChatBubbleLeftEllipsisIcon />
  },
  {
    id: 'email',
    title: 'Email',
    icon: <EnvelopeIcon />,
    navLink: '/emails'
  },
  {
    id: 'notes',
    title: 'Notes',
    icon: <PencilSquareIcon />,
    navLink: '/notes'
  },
  {
    id: 'scrumBoards',
    title: 'Scrum Boards (Demo)',
    icon: <ViewColumnsIcon />,
    navLink: '/scrum-boards'
  },
  {
    id: 'settings',
    title: 'Settings',
    icon: <Cog8ToothIcon />,
    children: [
      {
        id: 'autoResponses',
        title: 'Auto Responses',
        navLink: '/settings/auto-responses'
      },
      {
        id: 'bannedContacts',
        title: 'Banned Contacts',
        navLink: '/settings/banned-contacts'
      },
      {
        id: 'quickText',
        title: 'Quick Text',
        navLink: '/settings/quick-text'
      }
    ]
  }
]
