import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../../../utility/axios'


export const fetchBoards = createAsyncThunk(
  'boards/loadBoards',
  async () => {
    return (await api.get('/api/ScrumBoards')).data.data
  }
)

export const fetchBoard = createAsyncThunk(
  'boards/loadBoard',
  async ({ id }) => {
    return (await api.get(`/api/ScrumBoards/${id}`)).data.data
  }
)

export const fetchMisc = createAsyncThunk(
  'boards/loadMisc',
  async () => {
    return (await api.get('/api/ScrumBoardCards/misc')).data.data
  }
)

export const fetchCardsOfBoardColumn = createAsyncThunk(
    'boards/ColumnCards',
    async ({columnId}) => {
      return (await api.get(`/api/ScrumBoardCards?columnId=${columnId}`)).data.data
    }
)

const sortItems = (a, b) => a.position - b.position


export const boardSlice = createSlice({
  name: 'boards',
  initialState: {
    boards: [],
    board: null,
    misc: {}
  },
  reducers: {
    // Boards
    addBoard: (state, action) => {
      if (!state.boards.find(board => board.id === action.payload.id)) state.boards.push(action.payload)
    },
    updateBoard: (state, action) => {
      state.boards = state.boards.map(board => {
        if (board.id === action.payload.id) return action.payload
        else return board
      })
    },
    updateBoardDetails: {
      reducer(state, action) {
        if (!state.board) throw new Error('Board NOT found.')
        state.board.name = action.payload.name
        state.board.description = action.payload.description
      },
      prepare(name, description) {
        return { payload: { name, description } }
      }
    },
    removeBoard: {
      reducer(state, action) {
        state.boards = state.boards.filter(board => board.id !== action.payload.boardId)
      },
      prepare(boardId) {
        return { payload: { boardId } }
      }
    },

    // Columns
    addColumn: (state, action) => {
      if (state.board.id !== action.payload.boardId || state.board.columns.find(col => col.id === action.payload.id)) {
        return
      }

      state.board.columns.push(action.payload)
      state.board.columns.sort(sortItems)
    },
    updateColumn: (state, action) => {
      if (state.board.id !== action.payload.boardId) return
      state.board.columns = state.board.columns.map(column => {
        if (column.id === action.payload.id) {
          return {
            ...column,
            name: action.payload.name
          }
        }
        return column
      })
    },
    removeColumn: {
      reducer: (state, action) => {
        const { boardId, columnId } = action.payload
        if (state.board.id !== boardId) return

        state.board.columns = state.board.columns.filter(column => column.id !== columnId)
      },
      prepare(boardId, columnId) {
        return { payload: { boardId, columnId } }
      }
    },

    // Cards
    addCard: (state, action) => {
      const { boardId, card } = action.payload
      if (state.board.id !== boardId) return

      const column = state.board.columns.find(item => item.id === card.columnId)
      if (column) {
        column.cards.push(card)
        column.cards.sort(sortItems)
      }
    },
    updateCard: (state, action) => {
      const { boardId, card } = action.payload
      if (state.board.id !== boardId) return

      state.board.columns = state.board.columns.map(column => {
        if (column.id === card.columnId) {
          return {
            ...column,
            cards: column.cards.map(_card => {
              if (_card.id === card.id) {
                return card
              } else return _card
            })
          }
        } else return column
      })
    },
    removeCard: {
      reducer(state, action) {
        const { boardId, columnId, cardId } = action.payload
        if (state.board.id !== boardId) return

        const column = state.board.columns.find(item => item.id === columnId)
        if (column) column.cards = column.cards.filter(item => item.id !== cardId)
      },
      prepare(boardId, columnId, cardId) {
        return { payload: { boardId, columnId, cardId } }
      }
    },

    // Sorting
    updateCardPos: (state, action) => {
      const { boardId, cardId, position, src, dst } = action.payload
      if (state.board.id !== boardId) return

      const column = state.board.columns.find(col => col.id.toString() === src.toString())
      const card = column?.cards.find(card => card.id.toString() === cardId.toString())

      if (!card) return

      card.position = position
      if (src === dst) {
        column.cards.sort(sortItems)
      } else {
        const _column = state.board.columns.find(col => col.id.toString() === dst.toString())
        if (!_column) return

        card.columnId = _column.id
        _column.cards.push(card)
        _column.cards.sort(sortItems)
        column.cards = column.cards.filter(_card => _card.id !== card.id)
      }
    },
    updateColumnPos: (state, action) => {
      const { boardId, src, position } = action.payload
      if (state.board.id !== boardId) return

      const column = state.board.columns.find(col => col.id.toString() === src.toString())
      if (column) {
        column.position = position
        state.board.columns.sort(sortItems)
      }
    },

    clearCurrentBoard: (state) => {
      state.board = null
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchBoards.fulfilled, (state, action) => {
      state.boards = action.payload
    })
    builder.addCase(fetchBoard.fulfilled, (state, action) => {
      state.board = action.payload
      action.payload.columns.sort(sortItems)
      action.payload.columns.forEach(column => {
        column.cards.sort(sortItems)
      })
    })
    builder.addCase(fetchMisc.fulfilled, (state, action) => {
      state.misc = action.payload
    })

    builder.addCase(fetchCardsOfBoardColumn.fulfilled, (state, action) => {
      const cards = action.payload

      if (cards && Array.isArray(cards) && cards.length > 0) {
        const card = action.payload[0]
        const column = state?.board?.columns.find(item => item.id === card.columnId)
        if (column) {
          column.cards = cards
          column.cards.sort(sortItems)
        }
      }
    })
  }
})


export const ACTIONS = boardSlice.actions
export const getBoards = state => state.boards.boards
export const getBoard = state => state.boards.board
export const getMisc = state => state.boards.misc

export default boardSlice.reducer
